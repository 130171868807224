<script lang="ts">
	import Button from '$lib/components/buttons/Button.svelte';
	import ButtonText from '$lib/components/buttons/ButtonText.svelte';
	import Flex from '$lib/components/Flex.svelte';
	import type { ModalNamespace } from '$lib/components/Modal/_PrivateModal.svelte';
	import Padding from '$lib/components/Padding.svelte';
	import * as m from '$lib/paraglide/messages.js';
	import { getContext } from 'svelte';

	let { isShowing } = getContext<ModalNamespace.Context>('Modal');
</script>

<Button
	backgroundColour="secondary-green-contrast"
	borderRadius={3}
	onclick={() => {
		isShowing.set(false);
	}}
	fullWidth
>
	<Padding all={1} fullWidth>
		<Flex fullWidth justify="center" gap={3}>
			<ButtonText colour="secondary-white">
				{m.direct_fuzzy_barbel_splash()}
			</ButtonText>
		</Flex>
	</Padding>
</Button>
